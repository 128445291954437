import { ChangeEvent, useState } from "react";

const amazon = require("../../../assets/images/giftcards/amazon.png");
const amex = require("../../../assets/images/giftcards/amex.png");
const google = require("../../../assets/images/giftcards/google.png");
const hulu = require("../../../assets/images/giftcards/hulu.png");
const itunes = require("../../../assets/images/giftcards/itunes.png");
const razer = require("../../../assets/images/giftcards/razer.png");
const steam = require("../../../assets/images/giftcards/steam.png");
const vanilla = require("../../../assets/images/giftcards/vanilla.png");
const exchange = require("../../../assets/images/Rates.png");

export const useSellGiftcardsHelper = () => {
  const [step, setStep] = useState<number>(1);
  const [payload, setPayload] = useState({
    token: "",
    name: "",
    img: "",
    Subcategory: "",
    totalAmount: "",
    quantity: 1,
  });

  const items = [
    { id: "amazon", name: "Amazon", fullName: "Amazon", img: amazon },
    { id: "itunes", name: "Itunes", fullName: "Itunes", img: itunes },
    { id: "google", name: "Google Play", fullName: "Google Play", img: google },
    { id: "steam", name: "Stream", fullName: "Stream", img: steam },
    { id: "razer", name: "Razer Gold", fullName: "Razer Gold", img: razer },
    { id: "vanilla", name: "Vanilla", fullName: "Vanilla", img: vanilla },
    {
      id: "amex",
      name: "American Express",
      fullName: "American Express",
      img: amex,
    },
    { id: "hulu", name: "Hulu", fullName: "Hulu", img: hulu },
  ];

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setPayload((prevPayload) => ({ ...prevPayload, [name]: value }));
  };

  return {
    step,
    setStep,
    payload,
    setPayload,
    items,
    handleChange,
    amex,
    google,
    hulu,
    itunes,
    razer,
    steam,
    vanilla,
    exchange,
  };
};
