import { useMutation } from "react-query";
import { axiosInstance } from "../../utils/axiosInstance";
import { message } from "antd";

type OrderPayload = {
  orderType: "Buy" | "Sell";
  cryptoType: string;
  network: string;
  walletAddress: string;
  amount: string;
  receivedCrypto?: string;
  networkFee: string;
  totalAmount: string;
  receipt: string;
};

export const useCreateOrderMutation = () => {
  return useMutation(
    (payload: OrderPayload) =>
      axiosInstance.post("/orders", payload).then((res) => res.data),
    {
      onSuccess: (data) => {
        message.success(data?.message || "Order created successfully");
      },
      onError: (error: any) => {
        message.error(
          error.response?.data?.message ||
            "An error occurred while creating the order"
        );
      },
    }
  );
};
