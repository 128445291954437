import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const useAutoLogout = () => {
  const [isInactive, setIsInactive] = useState(false);
  const navigate = useNavigate();

  let timeout = 900000;

  const resetTimer = useCallback(() => {
    setIsInactive(false);
  }, []);

  const handleLogout = useCallback(() => {
    navigate('/');
    // TODO: enable back
    // localStorage.clear();
  }, [navigate]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const activityEvents = ['mousemove', 'keydown', 'mousedown', 'touchstart'];

  useEffect(() => {
    const handleActivity = () => {
      resetTimer();
    };

    activityEvents.forEach((event) =>
      window.addEventListener(event, handleActivity)
    );

    const timer = setTimeout(() => {
      setIsInactive(true);
    }, timeout);

    if (isInactive) {
      handleLogout();
    }

    return () => {
      activityEvents.forEach((event) =>
        window.removeEventListener(event, handleActivity)
      );
      clearTimeout(timer);
    };
  }, [timeout, isInactive, handleLogout, resetTimer, activityEvents]);

  return isInactive;
};

export default useAutoLogout;
