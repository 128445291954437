import React, { ReactNode } from "react";

type Props = { children: ReactNode };

const AuthLayout = ({ children }: Props) => {
  const banner = require("../../../assets/images/banner.png");
  return (
    <div className="md:flex block h-dvh overflow-hidden bg-black">
      <div className="md:w-6/12 md-block h-dvh overflow-y-scroll">
        {children}
      </div>
      <div className="w-6/12">
        <img src={banner} alt="" className="w-full h-dvh object-fit" />
      </div>
    </div>
  );
};

export default AuthLayout;
