import React from "react";
import UserAppLayout from "../../../common/UserAppLayout";
import Portfolio from "./Portfolio";
import QuickActions from "./QuickActions";
import RecentActivities from "./RecentActivities";
import UserCard from "./UserCard";
import KycVerification from "./KycVerification";

const Dashboard = () => {
  return (
    <UserAppLayout>
      <UserCard />
      <Portfolio />
      <KycVerification />
      <QuickActions />
      <RecentActivities />
    </UserAppLayout>
  );
};

export default Dashboard;
