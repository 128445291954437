import React, { useState } from "react";
import AuthLayout from "../AuthLayout";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useLoginMutation } from "../../../../hooks/Auth/useLoginMutation";
import { useConfirmLoginMutation } from "../../../../hooks/Auth/useConfirmLoginMutation";

const Login = () => {
  const [level, setLevel] = useState(0);
  const [verifyPayload, setVerifyPayload] = useState({ id: 0, otp: "" });
  const [payload, setPayload] = useState({
    email: "",
  });

  const Logo = require("../../../../assets/images/logo.png");
  const { mutateAsync, isLoading: isLoggingin } = useLoginMutation();
  const { mutateAsync: confirmMutation, isLoading: isConfirming } =
    useConfirmLoginMutation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPayload((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    if (payload.email) {
      await mutateAsync(payload, {
        onSuccess: async (data: any) => {
          await setVerifyPayload({ ...verifyPayload, id: data?.data?.id });
          setLevel(1);
        },
      });
    }
  };

  const handleVerify = async () => {
    if (payload.email) {
      await confirmMutation(verifyPayload, {
        onSuccess: () => {
          setLevel(1);
        },
      });
    }
  };

  return (
    <AuthLayout>
      <motion.div
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -100 }}
        transition={{ duration: 0.5 }}
        className="flex items-center justify-center min-h-screen"
      >
        <div className="w-[400px]">
          <img src={Logo} alt="logo" className="mx-auto h-[35px] mb-[40px]" />

          {level === 0 ? (
            <div>
              <p className="text-white text-[30px] text-center">Welcome back</p>
              <h3 className="mt-[10px] text-[#727272]">Email Address</h3>
              <input
                type="email"
                name="email"
                value={payload.email}
                onChange={handleChange}
                className="mt-[10px] w-full p-2 rounded-md bg-[#040404] text-[#727272] border-[1px] border-[#242424]"
                placeholder="Enter your email"
              />
              <button
                onClick={handleSubmit}
                disabled={!payload.email}
                className="w-full mb-[20px] mt-[20px] bg-gradient-to-b from-[#686DEA] to-[#1C1F60] text-white font-bold py-2 px-4 rounded-md disabled:opacity-50"
              >
                {isLoggingin ? "Submitting" : "Login"}
              </button>
            </div>
          ) : (
            <div>
              <p className="text-white text-[30px] text-center">
                OTP Verification
              </p>
              <h3 className="mt-[10px] text-[#727272]">Enter Login Code</h3>
              <input
                type="number"
                name="code"
                value={verifyPayload?.otp}
                onChange={(e) => {
                  setVerifyPayload({ ...verifyPayload, otp: e?.target.value });
                }}
                className="mt-[10px] w-full p-2 rounded-md bg-[#040404] text-[#727272] border-[1px] border-[#242424]"
                placeholder="Enter the otp"
              />
              <button
                onClick={handleVerify}
                disabled={!verifyPayload?.otp}
                className="w-full mb-[20px] mt-[20px] bg-gradient-to-b from-[#686DEA] to-[#1C1F60] text-white font-bold py-2 px-4 rounded-md disabled:opacity-50"
              >
                {isConfirming ? "Submitting" : "Submit"}
              </button>
            </div>
          )}

          <div className="mt-[30px] text-white text-center">
            Don't have an account?{" "}
            <Link to="/create" className="text-[#51B590] underline">
              Create
            </Link>
          </div>
        </div>
      </motion.div>
    </AuthLayout>
  );
};

export default Login;
