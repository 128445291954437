// import React, { useState, ChangeEvent } from "react";
// import AuthLayout from "../AuthLayout";
// import { Link } from "react-router-dom";
// import { motion } from "framer-motion";

// const SignUp = () => {
//   const [payload, setPayload] = useState({
//     fullname: "",
//     email: "",
//     phone: "",
//     code: "",
//     password: "",
//   });

//   const Logo = require("../../../assets/images/logo.png");

//   const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
//     const { name, value } = e.target;
//     setPayload((prev) => ({ ...prev, [name]: value }));
//   };

//   return (
//     <AuthLayout>
//       <motion.div
//         initial={{ opacity: 0, y: 100 }}
//         animate={{ opacity: 1, y: 0 }}
//         exit={{ opacity: 0, y: -100 }}
//         transition={{ duration: 0.5 }}
//         className="flex items-center justify-center min-h-screen"
//       >
//         <div className="max-w-[400px] py-[30px]">
//           <img src={Logo} alt="logo" className="mx-auto h-[35px] mb-[40px]" />
//           <p className="text-white text-[30px] text-center">
//             Create An Account
//           </p>

//           <h3 className="mt-[10px] text-[#727272]">Full Name</h3>
//           <input
//             type="text"
//             name="fullname"
//             value={payload.fullname}
//             onChange={handleChange}
//             className="mt-[10px] w-full p-2 rounded-md bg-[#040404] text-[#727272] border border-[#242424]"
//             placeholder="Enter your full name"
//           />

//           <h3 className="mt-[10px] text-[#727272]">Email Address</h3>
//           <input
//             type="email"
//             name="email"
//             value={payload.email}
//             onChange={handleChange}
//             className="mt-[10px] w-full p-2 rounded-md bg-[#040404] text-[#727272] border border-[#242424]"
//             placeholder="Enter your email address"
//           />

//           <h3 className="mt-[10px] text-[#727272]">Phone Number</h3>
//           <input
//             type="tel"
//             name="phone"
//             value={payload.phone}
//             onChange={handleChange}
//             className="mt-[10px] w-full p-2 rounded-md bg-[#040404] text-[#727272] border border-[#242424]"
//             placeholder="Enter your phone number"
//           />

//           <h3 className="mt-[10px] text-[#727272]">Referral Code (Optional)</h3>
//           <input
//             type="text"
//             name="code"
//             value={payload.code}
//             onChange={handleChange}
//             className="mt-[10px] w-full p-2 rounded-md bg-[#040404] text-[#727272] border border-[#242424]"
//             placeholder="Enter referral code"
//           />

//           <h3 className="mt-[10px] text-[#727272]">Password</h3>
//           <input
//             type="password"
//             name="password"
//             value={payload.password}
//             onChange={handleChange}
//             className="mt-[10px] w-full p-2 rounded-md bg-[#040404] text-[#727272] border border-[#242424]"
//             placeholder="Enter your password"
//           />

//           <button
//             disabled={!payload.email || !payload.password}
//             className="w-full mb-[20px] mt-[20px] bg-gradient-to-b from-[#686DEA] to-[#1C1F60] text-white font-bold py-2 px-4 rounded-md disabled:opacity-50"
//           >
//             Sign Up
//           </button>

//           <div className="mt-[30px] text-white text-center">
//             Already have an account?{" "}
//             <Link to="/" className="text-[#51B590] underline">
//               Sign in
//             </Link>
//           </div>
//         </div>
//       </motion.div>
//     </AuthLayout>
//   );
// };

// export default SignUp;

import React, { useState } from "react";
import AuthLayout from "../AuthLayout";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useSignupMutation } from "../../../../hooks/Auth/useSignupMutation";

const SignUp = () => {
  const [payload, setPayload] = useState({
    email: "",
    firstName: "",
    lastName: "",
  });

  const Logo = require("../../../../assets/images/logo.png");
  const { mutateAsync } = useSignupMutation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPayload((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    if (payload.email && payload.firstName && payload.lastName) {
      await mutateAsync(payload);
    }
  };

  return (
    <AuthLayout>
      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -100 }}
        transition={{ duration: 0.5 }}
        className="flex items-center justify-center min-h-screen"
      >
        <div className="max-w-[400px]">
          <img src={Logo} alt="logo" className="mx-auto h-[35px] mb-[40px]" />
          <p className="text-white text-[30px] text-center">
            Create an Account
          </p>

          <h3 className="mt-[10px] text-[#727272]">Email Address</h3>
          <input
            type="email"
            name="email"
            value={payload.email}
            onChange={handleChange}
            className="mt-[10px] w-full p-2 rounded-md bg-[#040404] text-[#727272] border-[1px] border-[#242424]"
            placeholder="Enter your email"
          />

          <h3 className="mt-[10px] text-[#727272]">First Name</h3>
          <input
            type="text"
            name="firstName"
            value={payload.firstName}
            onChange={handleChange}
            className="mt-[10px] w-full p-2 rounded-md bg-[#040404] text-[#727272] border-[1px] border-[#242424]"
            placeholder="Enter your first name"
          />

          <h3 className="mt-[10px] text-[#727272]">Last Name</h3>
          <input
            type="text"
            name="lastName"
            value={payload.lastName}
            onChange={handleChange}
            className="mt-[10px] w-full p-2 rounded-md bg-[#040404] text-[#727272] border-[1px] border-[#242424]"
            placeholder="Enter your last name"
          />

          <button
            onClick={handleSubmit}
            disabled={!payload.email || !payload.firstName || !payload.lastName}
            className="w-full mb-[20px] mt-[20px] bg-gradient-to-b from-[#686DEA] to-[#1C1F60] text-white font-bold py-2 px-4 rounded-md disabled:opacity-50"
          >
            Submit
          </button>

          <div className="mt-[30px] text-white text-center">
            Already have an account?{" "}
            <Link to="/" className="text-[#51B590] underline">
              Login
            </Link>
          </div>
        </div>
      </motion.div>
    </AuthLayout>
  );
};

export default SignUp;
