import { Modal } from "antd";
import React, { useState } from "react";

const RecentActivities = () => {
  const btc = require("../../../assets/images/btcc.png");
  const sol = require("../../../assets/images/sol.png");
  const usdt = require("../../../assets/images/usdt.png");
  const wave = require("../../../assets/images/wave.png");
  const eth = require("../../../assets/images/eth.png");

  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = (e: React.MouseEvent<HTMLElement>) => {
    console.log(e);
    setOpen(false);
  };

  const handleCancel = (e: React.MouseEvent<HTMLElement>) => {
    console.log(e);
    setOpen(false);
  };

  const data = [
    {
      id: 1,
      title: "Bought 10.60 SOL",
      date: "July 24, 20:40",
      amount: "NGN 2,544,000",
      status: "Pending",
      coin: "Sol",
    },
    {
      id: 2,
      title: "Bought 10.60 SOL",
      date: "July 24, 20:40",
      amount: "NGN 2,544,000",
      status: "Processing",
      coin: "Eth",
    },
    {
      id: 3,
      title: "Bought 10.60 SOL",
      date: "July 24, 20:40",
      amount: "NGN 2,544,000",
      status: "Completed",
      coin: "Wave",
    },
    {
      id: 4,
      title: "Bought 10.60 SOL",
      date: "July 24, 20:40",
      amount: "NGN 2,544,000",
      status: "Completed",
      coin: "Btc",
    },
    {
      id: 5,
      title: "Bought 10.60 SOL",
      date: "July 24, 20:40",
      amount: "NGN 2,544,000",
      status: "Completed",
      coin: "USDT",
    },
  ];

  return (
    <div>
      <h3 className="text-white font-medium mt-[40px] md:text-[16px] text-[11px]">
        Recent Activity
      </h3>
      <div
        className="md:block hidden mt-[15px] bg-[#242424] p-[1px] rounded-md mb-[100px] cursor-pointer"
        onClick={showModal}
      >
        {data.map((datum: any) => (
          <div
            key={datum?.id}
            className="text-white px-[18px] py-[12px] bg-[#0D0D0D] mb-[1px] rounded-md"
          >
            <div className="flex items-center">
              <div className="w-1/12">
                {datum?.coin === "USDT" && (
                  <img src={usdt} className="h-[40px] w-[40px]" alt="usdt" />
                )}
                {datum?.coin === "Btc" && (
                  <img src={btc} className="h-[40px] w-[40px]" alt="btc" />
                )}
                {datum?.coin === "Wave" && (
                  <img src={wave} className="h-[40px] w-[40px]" alt="wave" />
                )}
                {datum?.coin === "Eth" && (
                  <img src={eth} className="h-[40px] w-[40px]" alt="eth" />
                )}
                {datum?.coin === "Sol" && (
                  <img src={sol} className="h-[40px] w-[40px]" alt="sol" />
                )}
              </div>
              <div className="w-5/12">
                <h3 className="font-bold">{datum?.title}</h3>
                <p className="text-[#727272]">{datum?.date}</p>
              </div>
              <div className="w-3/12">{datum?.amount}</div>
              <div
                className={`w-3/12 text-right ${
                  datum?.status === "Completed" && "text-[#51B590]"
                } ${datum?.status === "Processing" && "text-[#6DA0FF]"}
                ${datum?.status === "Pending" && "text-[#FFAE70]"}`}
              >
                {datum?.status}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="md:hidden block mt-[15px] bg-[#242424] p-[1px] rounded-md mb-[100px]">
        {data.map((datum: any) => (
          <div
            key={datum?.id}
            className="text-white px-[18px] py-[12px] bg-[#0D0D0D] mb-[1px] rounded-md md:text-[16px] text-[11px] cursor-pointer"
            onClick={showModal}
          >
            <div className="flex justify-between">
              <span className="flex items-center flex-row gap-[10px]">
                <div className="">
                  {datum?.coin === "USDT" && (
                    <img src={usdt} className="h-[40px] w-[40px]" alt="usdt" />
                  )}
                  {datum?.coin === "Btc" && (
                    <img src={btc} className="h-[40px] w-[40px]" alt="btc" />
                  )}
                  {datum?.coin === "Wave" && (
                    <img src={wave} className="h-[40px] w-[40px]" alt="wave" />
                  )}
                  {datum?.coin === "Eth" && (
                    <img src={eth} className="h-[40px] w-[40px]" alt="eth" />
                  )}
                  {datum?.coin === "Sol" && (
                    <img src={sol} className="h-[40px] w-[40px]" alt="sol" />
                  )}
                </div>
                <div className="">
                  <h3 className="">{datum?.title}</h3>
                  <p className="text-[#474747]">{datum?.date}</p>
                </div>
              </span>
              <span>
                <h3 className="">{datum?.amount}</h3>
                <p
                  className={`w-3/12 text-right ${
                    datum?.status === "Completed" && "text-[#51B590]"
                  } ${datum?.status === "Processing" && "text-[#6DA0FF]"}
                ${datum?.status === "Pending" && "text-[#FFAE70]"}`}
                >
                  {datum?.status}
                </p>
              </span>
            </div>
          </div>
        ))}
      </div>
      <Modal
        title=""
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        // okButtonProps={{ disabled: true }}
        // cancelButtonProps={{ disabled: true }}
        footer={null}
        className=""
      >
        <div className="border-b-[1px] borde-[#242424] pb-[10px]">
          <h3 className="text-[16px] text-white font-bold">Bought 0.56 SOL</h3>
          <p className="text-[12pz] text-[#C2C2C2]">July 24, 2024</p>
        </div>
        <div className="my-[18px] rounded-[10px] border-[1px] border-[#2d2d2d]">
          <div className="p-2 flex items-center justify-between border-b-[1px] border-[#2d2d2d]">
            <span className="text-[#c2c2c2]">Status</span>
            <span className="text-[#FFAE70]">Pending</span>
          </div>
          <div className="p-2 flex items-center justify-between border-b-[1px] border-[#2d2d2d]">
            <span className="text-[#c2c2c2]">Order ID:</span>
            <span className="text-[#FFAE70]">ksjfwfwfniwufuiw</span>
          </div>
        </div>
        <h3 className="mt-[18px] text-[#727272] mb-[10px] text-[13px]">
          Token Details:
        </h3>
        <div className="my-[18px] rounded-[10px] border-[1px] border-[#2d2d2d]">
          <div className="p-2 flex items-center justify-between border-b-[1px] border-[#2d2d2d] text-[#c2c2c2]">
            <span className="">Token:</span>
            <span className="">Sol</span>
          </div>
          <div className="p-2 flex items-center justify-between border-b-[1px] border-[#2d2d2d] text-[#c2c2c2]">
            <span className="">Network:</span>
            <span className="">Solana</span>
          </div>
          <div className="p-2 flex items-center justify-between text-[#c2c2c2]">
            <span className="">Wallet Address:</span>
            <span className="">OXE378EVEGEG7CY9Y3GGC0C</span>
          </div>
        </div>
        <h3 className="mt-[18px] text-[#727272] mb-[10px] text-[13px]">
          Amount
        </h3>
        <div className="my-[18px] rounded-[10px] border-[1px] border-[#2d2d2d]">
          <div className="p-2 flex items-center justify-between border-b-[1px] border-[#2d2d2d] text-[#c2c2c2]">
            <span className="">Amount:</span>
            <span className="">160,000 NGN</span>
          </div>
          <div className="p-2 flex items-center justify-between border-b-[1px] border-[#2d2d2d] text-[#c2c2c2]">
            <span className="">You will Receive:</span>
            <span className="">0.05623 SOL</span>
          </div>
          <div className="p-2 flex items-center justify-between border-b-[1px] border-[#2d2d2d] text-[#c2c2c2]">
            <span className="">Networ Fee:</span>
            <span className="">250 NGN</span>
          </div>
          <div className="p-2 flex items-center justify-between text-[#c2c2c2]">
            <span className="">Max Total:</span>
            <span className="">160,250 NGN</span>
          </div>
        </div>
        <button className="p-2 bg-[#232323] w-full rounded-md text-white flex gap-2 items-center justify-center">
          <PhoneIcon />
          Report Transaction
        </button>
      </Modal>
    </div>
  );
};

export default RecentActivities;

const PhoneIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="size-5"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
      />
    </svg>
  );
};
