import React from "react";

const Portfolio = () => {
  // const pattern = require("../../../assets/images/pattern.png");
  return (
    <div>
      <p className="font-bold mb-[15px] text-white md:block hidden">
        Portfolio
      </p>
      <div className="overflow-x-auto">
        <div className="flex md:flex-wrap flex-nowrap gap-[12px] whitespace-nowrap no-scrollbar">
          <div className="rounded-md w-[243px] bg-[#5D60A6] text-white relative flex-shrink-0">
            {/* <img src={pattern} alt="" className="absolute right-0 h-full" /> */}
            <div className="p-[15px]">
              <p className="md:mb-[35px] mb-[30px] md:text-[16px] text-[11px]">
                Crypto Volume
              </p>
              <div className="flex justify-between items-center">
                <div>
                  <h3 className="md:text-[22px] text-[20px] font-bold">
                    $ 40,000.00
                  </h3>
                  <p>₦ 400,000</p>
                </div>
                <RightCaret />
              </div>
            </div>
          </div>
          <div className="p-[15px] rounded-md w-[243px] bg-[#51B590] text-white flex-shrink-0">
            <p className="md:mb-[35px] mb-[30px] md:text-[16px] text-[11px]]">
              Giftcards Volume
            </p>
            <div className="flex justify-between items-center">
              <div>
                <h3 className="md:text-[22px] text-[20px] font-bold">
                  $ 40,000.00
                </h3>
                <p>₦ 400,000</p>
              </div>
              <RightCaret />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;

const RightCaret = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="size-4"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m8.25 4.5 7.5 7.5-7.5 7.5"
      />
    </svg>
  );
};
